<illustration
  [name]="'datamigration' | translate"
></illustration>

<p-panel
  [header]="balancePanel.header"
  class="p-d-block p-mb-3"
  [toggleable]="true"
>
  <div class="p-grid">
    <div class="p-col-12">
      <div>Start Time: {{pposParams['PPOS_BALANCE_BEGTIME']?pposParams['PPOS_BALANCE_BEGTIME']:''}}</div>
      <div>Step: {{pposParams['PPOS_BALANCE_SIZE']?pposParams['PPOS_BALANCE_SIZE']:''}}</div>
      <div>End Time: {{pposParams['PPOS_BALANCE_ENDTIME']?pposParams['PPOS_BALANCE_ENDTIME']:''}}</div>
    </div>
    <div class="p-col-12 p-text-right">
      <ng-container *ngFor="let btn of balancePanel.btns">
        <button
          pButton
          type="button"
          [ngClass]="btn.class"
          [label]="btn.label"
          (click)="btn.click()"
          [disabled]="!pposDataEditEnable&&(btn.label=='Refresh Status'||btn.label=='Upload'||btn.label=='Run')"
        ></button>
      </ng-container>
    </div>
  </div>
</p-panel>

<p-panel
  [header]="serialPanel.header"
  class="p-d-block p-mb-3"
  [toggleable]="true"
>
  <div class="p-grid">
    <div class="p-col-12">
      <div>Start Time: {{pposParams['PPOS_SERIAL_BEGTIME']?pposParams['PPOS_SERIAL_BEGTIME']:''}}</div>
      <div>Step: {{pposParams['PPOS_SERIAL_SIZE']?pposParams['PPOS_SERIAL_SIZE']:''}}</div>
      <div>End Time: {{pposParams['PPOS_SERIAL_ENDTIME']?pposParams['PPOS_SERIAL_ENDTIME']:''}}</div>
    </div>
    <div class="p-col-12 p-text-right">
      <ng-container *ngFor="let btn of serialPanel.btns">
        <button
          pButton
          type="button"
          [ngClass]="btn.class"
          [label]="btn.label"
          (click)="btn.click()"
          [disabled]="!pposDataEditEnable&&(btn.label=='Refresh Status'||btn.label=='Upload'||btn.label=='Run')"
        ></button>
      </ng-container>
    </div>
  </div>
</p-panel>

<p-panel
  [header]="variancePanel.header"
  class="p-d-block"
  [toggleable]="true"
>
  <div class="p-grid">
    <div class="p-col-12 p-text-right">
      <ng-container *ngFor="let btn of variancePanel.btns">
        <button
          pButton
          type="button"
          [ngClass]="btn.class"
          [label]="btn.label"
          (click)="btn.click()"
          [disabled]="!pposDataEditEnable&&(btn.label=='Refresh Status'||btn.label=='Upload'||btn.label=='Run')"
        ></button>
      </ng-container>
    </div>
  </div>
</p-panel>

<div class="cus-loading-overlay" *ngIf="loading">
    <i class="loading-icon pi pi-spin pi-spinner"></i>
</div>
